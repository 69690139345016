<template>
  <v-card class="py-5">
    <v-card-title  v-if="item" class="justify-center text-h4 pb-10 primary--text">ผลลัพธ์การดำเนินงาน - {{item.car_plate + " " + item.province}}</v-card-title>
    <v-card-text class="popup-background">

      <v-row v-if="item" justify="center">
        <v-col cols="10">

          <v-row>
            <v-col cols="6">
              <v-text-field :value="item.customer_phone_number" label="เบอร์โทรลูกค้า" class="mt-2" outlined dense background-color="white" disabled/>
            </v-col>

            <v-col cols="6"> </v-col>
            <v-col cols="6">
              <v-text-field
                :value="item.insurance_data.third_insurance_name"
                label="พ.ร.บ. + นายหน้า"
                outlined dense background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.insurance_data.third_insurance_type"
                label="ประเภท" outlined dense background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <DatePicker
                v-model="item.insurance_data.third_insurance_protection_start_date"
                @onChange="(val) => (item.insurance_data.third_insurance_protection_start_date = val)"
                label="วันเริ่มคุ้มครอง พ.ร.บ."
                outlined dense background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <DatePicker 
                v-model="item.insurance_data.third_insurance_protection_end_date"
                @onChange="(val) => (item.insurance_data.third_insurance_protection_end_date = val)"
                label="วันสิ้นสุดคุ้มครอง พ.ร.บ."
                outlined dense background-color="white"
                disabled
              /> 
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.insurance_data.third_insurance_number"
                label="เลขกรมธรรม์"
                outlined dense background-color="white"
                :error-messages="$v.item.third_insurance_number.$error ? $error_text : ''"
                disabled
              />
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.insurance_data.third_insurance_modifier"
                label="ผู้บันทึก พ.ร.บ."
                outlined dense background-color="white"
                disabled
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="item.insurance_data.insurance_name"
                label="ประกันภัย + นายหน้า"
                outlined dense background-color="white"
                disabled
              />
            </v-col>

            <v-col cols="6">
              <v-text-field 
                :value="item.insurance_data.insurance_type"
                label="ประเภท"
                outlined dense background-color="white"
                disabled
              />
            </v-col>

            <v-col cols="-">
              <DatePicker
                v-model="item.insurance_data.insurance_protection_start_date"
                @onChange="(val) => (item.insurance_data.insurance_protection_start_date = val)"
                label="วันเริ่มคุ้มครองประกันภัย"
                outlined dense background-color="white"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <DatePicker
                v-model="item.insurance_data.insurance_protection_end_date"
                @onChange="(val) => (item.insurance_data.insurance_protection_end_date = val)"
                label="วันสิ้นสุดคุ้มครองประกันภัย"
                outlined dense background-color="white"
                disabled
              /> 
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="item.insurance_data.insurance_number"
                label="เลขกรมธรรม์"
                outlined dense background-color="white"
                :error-messages="$v.item.insurance_number.$error ? $error_text : ''"
                disabled
              />
            </v-col>

            <v-col cols="6"></v-col>

            <v-col cols="6">
              <v-text-field 
                v-model="item.insurance_data.insurance_modifier" 
                label="ผู้บันทึกประกัน" 
                outlined dense background-color="white"
                disabled
              />
            </v-col>

            <v-col cols="6">
              <v-row>
                <v-radio-group class="mt-1 mb-3" row v-model="item.is_success" :error-messages="$v.item.is_success.$error ? $error_text : ''" disabled>
                  <div class="mr-5">ผลลัพธ์การดำเนินงาน :</div>
                  <v-radio name="" label="สำเร็จ" :value="true"></v-radio>
                  <v-radio name="" label="ไม่สำเร็จ" :value="false"></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>

            <v-col cols="6"></v-col>

            <!-- <v-col cols="6">
              <v-textarea :value="item.reason_on_fail" label="หมายเหตุ" outlined dense background-color="white" disabled/>
            </v-col> -->

            <v-col cols="12">
              <v-textarea :value="item.note" label="โน๊ต" outlined dense background-color="white"/>
            </v-col>

          </v-row>
        </v-col>
      </v-row>


    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">บันทึกข้อมูล</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DatePicker from "@/components/DatePicker.vue";

import * as moment from "moment";

export default {
  components: {
    DatePicker
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  validations: {
    item: {
      third_insurance_number: { required },
      insurance_number: { required },
      customer_phone_number: '',
      is_success: { required },
    },
  },
  data () {
    return {
      loading: false,
      
    }
  },
  created () {
    
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    async save(){
      this.loading = true;
        let payload = {
          process_id: this.item._id,
          insurance_data: {
            third_insurance_number: this.item.insurance_data.third_insurance_number,
            third_insurance_protection_start_date: this.item.insurance_data.third_insurance_protection_start_date,
            third_insurance_protection_end_date: this.item.insurance_data.third_insurance_protection_end_date,
            third_insurance_modifier: this.third_insurance_modifier,
            insurance_number: this.item.insurance_data.insurance_number,
            insurance_protection_start_date: this.item.insurance_data.insurance_protection_start_date,
            insurance_protection_end_date: this.item.insurance_data.insurance_protection_end_date,
            insurance_modifier: this.insurance_modifier,
          },
          is_success: this.item.is_success,
          reason_on_fail: this.item.reason_on_fail,
          note: this.item.note,
        };
        let body = {
          token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/record_insurance`, body)
          .then((res) =>{
            this.$emit('onClose');
          })
          .catch((err) => {
            console.log('err',err);
          });
        this.loading = false;
      this.close();
    },
  },
  watch : {
    
  },
  computed: {
    isUpdate() {
      return this.item;
    },
  }
}
</script>

