<template>
  <div class="px-3">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ติดตามงาน พรบ. และประกันภัย</div>
      <div style="width: 400px">
        <v-text-field
          class="ml-10"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา เลขทะเบียน ชื่อลูกค้า"
          outlined
          rounded
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>

      <template v-if="tab === 0 || tab === 1">
        <div style="width: 200px">
          <v-select
            label="สถานะ"
            v-model="carTypeId"
            :items="[
              { text: 'ทั้งหมด', value: 0 },
              { text: 'รถยนต์', value: 1 },
              { text: 'รถจักรยานยนต์', value: 2 },
              { text: 'อื่น ๆ', value: 3 },
            ]"
            item-value="value"
            item-text="text"
            outlined
            rounded
            dense
            hide-details
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="tab == 0"
          color="error"
          outlined
          small
          class="ml-5"
          @click="cancelInprogressProcess()"
          :disabled="!selected.inprogress.length"
          >ยกเลิก</v-btn
        >
        <v-btn
          v-if="tab == 1"
          color="error"
          outlined
          small
          class="ml-5"
          @click="$refs.complete.selected = []"
          :disabled="!selected.complete.length"
          >ยกเลิก</v-btn
        >
        <v-btn
          v-if="tab == 0"
          color="success"
          small
          class="ml-2"
          @click="processInsuranceJob()"
          :disabled="!selected.inprogress.length"
        >
          งานเสร็จแล้ว
        </v-btn>
      </template>
    </v-toolbar>

    <v-card>
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="white"
        active-class="light black--text"
      >
        <v-tabs-slider color="light"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <InProgress
            ref="inprogress"
            :key="'inprogress_' + tab"
            @on-selected="selected.inprogress = $event"
            :car-type-id="carTypeId"
            :search="search"
            :update-list="updateInprogress"
          />
        </v-tab-item>
        <v-tab-item>
          <Complete
            ref="complete"
            :key="'complete_' + tab"
            @on-selected="selected.complete = $event"
            :car-type-id="carTypeId"
            :search="search"
            :update-list="updateComplete"
          />
        </v-tab-item>
        <v-tab-item>
          <Cancel
            ref="cancel"
            :key="'cancel_' + tab"
            @on-selected="selected.cancel = $event"
            :search="search"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import InProgress from './InProgress';
import Complete from './Complete';
import Cancel from './Cancel';

export default {
  components: {
    InProgress,
    Complete,
    Cancel,
  },
  data: () => ({
    search: '',
    tab: null,
    items: ['กำลังดำเนินการ', 'งานเสร็จสมบูรณ์ (รอลูกค้ามารับ)', 'ยกเลิก'],
    carTypeId: 0,
    selected: {
      inprogress: [],
      complete: [],
      cancel: [],
    },
    updateInprogress: 0,
    updateComplete: 0,
    updateCancel: 0,
  }),
  mounted() {},
  methods: {
    async processInsuranceJob() {
      this.loading = true;
      let process_array = this.$refs.inprogress.selected.map((x) => {
        return x._id;
      });
      let body = {
        token: this.$jwt.sign(
          { process_array: process_array },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/insurance_job_on_process`, body)
        .then((res) => {
          this.$refs.inprogress.selected = [];
          this.tab = 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async cancelInprogressProcess() {
      this.$alertConfirm({ title: 'ยืนยันยกเลิกการติดตามงาน ', text: '' }).then(
        async (result) => {
          if (result.isConfirmed) {
            let process_array = this.$refs.inprogress.selected.map((x) => {
              return x._id;
            });
            let body = {
              token: this.$jwt.sign(
                { process_array: process_array },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/process/cancel_process`, body)
              .then((res) => {
                this.selected.pending = [];
                this.tab = 2;
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });

            this.updateInprogress++;
            this.$refs.inprogress.selected.length = 0;
          }
        }
      );
    },
  },
  watch: {
    tab: function (newVal, oldVal) {
      if (newVal) {
        this.search = '';
        this.taxRenewalRound = '';
      }
    },
  },
};
</script>