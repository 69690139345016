<template>
  <div>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @page-count="pageCount = $event"
      show-select
      item-key="_id"
      v-model="selected"
    >
      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>
      <template v-slot:[`item.is_original_document`]="{ item }">
        <div v-if="item.is_original_document">ตัวจริง</div>
        <div v-else>สำเนา</div>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="900" v-model="dialogForm">
      <CancelForm :item="dialogFormData" @onClose="dialogForm = false" />
    </v-dialog>
  </div>
</template>

<script>
import CancelForm from './CancelForm';

export default {
  components: {
    CancelForm,
  },
  props: [
    'carTypeId',
    'search',
  ],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,

    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ประเภท', value: 'car_type', filterable: false },
      { text: 'เอกสาร', value: 'is_original_document', filterable: false },
      { text: 'พรบ.', value: 'insurance_name', filterable: false },
      { text: 'ประกันภัย', value: '3rd_insurance_name', filterable: false },
      { text: 'เบอร์โทร', value: 'customer_phone_number', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      // { text: 'หมายเหตุ', value: 'reason_on_fail', filterable: false },
    ],

    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 1,
      tracking_status: 4,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 100,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },

    openDialog() {
      this.dialogForm = true;
    },

    cancel() {},
  },
  watch: {
    search(nv, ov) {
      if (nv != ov) {
        this.filter.start_with = nv;
        this.getData();
      }
    },
  }
};
</script>