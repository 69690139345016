<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->สรุปรถที่จะไปต่อภาษี</v-card-title>
    <v-card-text class="popup-background">

      
      <v-row >
        <v-col cols="10" offset="1" class="pb-0">
          <v-text-field v-model="form.f_a" label="รอบงานที่" outlined dense background-color="white" :error-messages="$v.form.f_a.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="รถที่ต่อแค่ 1 ปี มี / คัน" outlined dense background-color="white" :error-messages="$v.form.f_b.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="เลขทะเบียน" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="รย. 2 มี" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="เลขทะเบียน" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="จำนวนรถทั้งหมด" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
          <v-text-field v-model="form.f_b" label="ราคารวม" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
        </v-col>
        <v-col cols="10" offset="1" class="mt-0 pt-0">
          <v-text-field v-model="form.f_b" label="ค่าข่องขนส่ง / บาท" outlined dense background-color="white" :error-messages="$v.form.f_c.$error ? $error_text : ''"/>
        </v-col>
        <v-col cols="1">
           <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <v-sheet class="pa-3">info</v-sheet>
          </v-menu>
        </v-col>
      </v-row>


    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">บันทึกข้อมูล</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

  export default {
    props: {
      item: {
        type: Object,
        default: null,
      },
    },
    validations: {
      form: {
        f_a: { required },
        f_b: { required },
        f_c: { required },
      },
    },
    data () {
      return {
        loading: false,
        form: {
          f_a: 1,
          f_b: 1,
          f_c: 1,
        }
      }
    },
    methods: {
      close() {
        this.$emit('onClose');
      },
      save(){
        this.$v.form.$touch();
        if(this.$v.form.$error) return;
        // submit data here baby. 
        this.close();
      },
    },
    computed: {
      isUpdate() {
        return this.item;
      }
    },
  }
</script>

